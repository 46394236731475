.alfafootercontainer {
    left: 0;
    width: 100vw;
    background-color: #fff;
}

.alfafooter {
    text-align: center;
    border: none;
    margin-top: 20px;
    background-color: var(--bs-al-blue);
}

.alfafooter .btn-link {
    color: #fff;
    font-size: 1.1rem;
    margin: 5px;
}

.alfafooter .btn-link:hover {
    color: #fff;
}

.alfafooter .btn-link:focus {
    box-shadow: none !important;
}


.alfafooter .btn-link {
    text-decoration: none;
}

.contactInfo {
    color: #433D3D;
    /*height: 120px;*/
    background-color: #F9F7F6;
    text-align: left;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 1rem;
    line-height: 1.3rem;
    padding-top: 2rem;
}

.contactInfo h1 {
    font-size: 1rem;
    font-weight: bold;
}

.contactInfo p {
    font-size: 1rem;
}

.contactInfo .al-info {
    font-size: 0.9rem;
    vertical-align: top;
    line-height: 0.9rem;
    font-style: italic;
}

.contactInfo p img {
    height: 50px;
    width: 50px;
    margin-right: 1rem;
    display: inline-block;
    float: left;
}

.arrow-down {
    width: 0;
    height: 0;
    border: 18px solid transparent;
    left: 50%;
    border-top-color: var(--bs-al-blue);
    position: absolute;
    margin-left: -18px;
    top: inherit;
}

.arrowbox {
    background-color: white;
    display: inline-block;
    height: 25px;
    width: 25px;
    margin-left: 8px;
    border-radius: 5px;
    color: var(--bs-al-black-7);
}

    .arrowbox > i {
        vertical-align: middle;
    }

html {
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 80px; /* Margin bottom by footer height */
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
}