$color-al-blue: #11387F;
$color-al-earth: #DB9276;
$color-al-sun: #FDCC61;
$color-al-water: #93C7C6;
$color-al-innovation: #007FC8;
$color-al-black-7: #3D3935;
$color-al-warm-gray-9: #847770;
$color-al-warm-gray-6: #A79C95;
$color-al-warm-gray-4: #B4ACA5;
$color-al-warm-gray-2: #CBC3BB;
$color-al-warm-gray-1: #D7D2CB;
$color-al-warm-gray-1-50: #ECE8E5;
$color-al-warm-gray-1-25: #F5F3F2;

$font-family-sans-serif: "Roboto",sans-serif,!default;

$theme-colors: ( 
    "primary": $color-al-blue, 
    "secondary": $color-al-warm-gray-1-25, 
    "info": $color-al-blue, 
    "bg-alfagrey70": $color-al-warm-gray-1, 
    "bg-alfagrey5": $color-al-warm-gray-1-50, 
    "al-blue": $color-al-blue,
    "al-earth": $color-al-earth,
    "al-sun": $color-al-sun,
    "al-water": $color-al-water,
    "al-innovation": $color-al-innovation,
    "al-black-7": $color-al-black-7,
    "al-warm-gray-9": $color-al-warm-gray-9,
    "al-warm-gray-6": $color-al-warm-gray-6,
    "al-warm-gray-4": $color-al-warm-gray-4,
    "al-warm-gray-2": $color-al-warm-gray-2,
    "al-warm-gray-1": $color-al-warm-gray-1,
    "al-warm-gray-1-50": $color-al-warm-gray-1-50,
    "al-warm-gray-1-25": $color-al-warm-gray-1-25,
);
$btn-border-radius: 3px;

html {
    overflow-y: scroll;
    overflow-x: hidden;
}

body {
    margin-bottom: 80px !important; /* Margin bottom by footer height */
}

.form-check-input:checked {
    background-color: $color-al-blue !important;
}
.form-check-input:focus {
    box-shadow: none !important;    
}

    @import "~bootstrap/scss/bootstrap";

    @font-face {
        font-family: "ALicons";
        src: local('ALicons'), url("./ALicons.woff") format("woff");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }


    [class^="icon-"],
    [class*=" icon-"] {
        font-family: "ALicons";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .icon-link:before {
        content: "\e60b";
    }

    .icon-envelope-o:before {
        content: "\f003";
    }

    .icon-envelope:before {
        content: "\f0e0";
    }

    .icon-facebook:before {
        content: "\f09a";
    }

    .icon-twitter:before {
        content: "\f099";
    }

    .icon-file:before {
        content: "\e921";
    }

    .icon-youtube:before {
        content: "\f167";
    }

    .icon-linkedin:before {
        content: "\f0e1";
    }

    .icon-fax:before {
        content: "\f1ac";
    }

    .icon-ellipsis-v:before {
        content: "\e92b";
    }

    .icon-share-alt:before {
        content: "\f1e0";
    }

    .icon-symbol-burger:before {
        content: "\e609";
    }

    .icon-download:before {
        content: "\e92c";
    }

    .icon-chevron-up:before {
        content: "\e923";
    }

    .icon-chevron-right:before {
        content: "\e924";
    }

    .icon-chevron-left:before {
        content: "\e925";
    }

    .icon-chevron-down:before {
        content: "\e926";
    }

    .icon-Check:before {
        content: "\e606";
    }

    .icon-symbol-search:before {
        content: "\e602";
    }

    .icon-symbol-close:before {
        content: "\e604";
    }

    .icon-map-marker:before {
        content: "\f041";
    }

    .icon-phone:before {
        content: "\f095";
    }

    .icon-angle-left:before {
        content: "\f103";
    }

    .icon-angle-right:before {
        content: "\f105";
    }

    .icon-angle-up:before {
        content: "\f106";
    }

    .icon-angle-down:before {
        content: "\f107";
    }

    .icon-arrow-up:before {
        content: "\e60a";
    }

    .icon-arrow-down:before {
        content: "\e601";
    }

    .icon-info:before {
        content: "\e605";
    }

    .icon-arrow-right:before {
        content: "\e922";
    }

    .icon-chevron-down-big:before {
        content: "\e927";
    }

    .icon-chevron-up-big:before {
        content: "\e92a";
    }

    .icon-chevron-left-mid:before {
        content: "\e907";
    }

    .icon-chevron-right-mid:before {
        content: "\e908";
    }

    .icon-general-enquiries:before {
        content: "\e92d";
    }

    .icon-partner-info:before {
        content: "\e92e";
    }

    .icon-product-info:before {
        content: "\e92f";
    }

    .icon-request-quote:before {
        content: "\e930";
    }

    .icon-support-tools:before {
        content: "\e931";
    }

    .icon-talkto-expert:before {
        content: "\e932";
    }

    .icon-past:before {
        content: "\e94f";
    }

    .icon-future:before {
        content: "\e950";
    }

    .icon-pdf:before {
        content: "\eadf";
    }

    .icon-link-ext:before {
        content: "\e935";
    }

    .icon-file-archive:before {
        content: "\e936";
    }

    .icon-file-code:before {
        content: "\e937";
    }

    .icon-file-excel:before {
        content: "\e938";
    }

    .icon-file-img:before {
        content: "\e939";
    }

    .icon-file-ppt:before {
        content: "\e93a";
    }

    .icon-file-text:before {
        content: "\e91a";
    }

    .icon-file-word:before {
        content: "\e93b";
    }

    .icon-file-empty:before {
        content: "\e93c";
    }

