.bg-al-row {
    --bs-table-bg: var(--bs-al-warm-gray-1-50);
    background-color: var(--bs-al-warm-gray-1-50);
    color: var(--bs-al-black-7);
}

.bg-al-row-asset {
    --bs-table-bg: var(--bs-white);
    background-color: var(--bs-white);
    color: var(--bs-al-black-7);
}

.catalog-table .bg-al-row-asset:hover {
    --bs-table-bg: var(--bs-al-warm-gray-1-25);
    /*background-color: var(--bs-al-warm-gray-1-25)*/
}

.level-1 {
    --bs-table-bg: #dcdbdb;
    background-color: #dcdbdb;
}

.catalog-table .bg-al-row.level-1:hover {
    --bs-table-bg: #d2d1d1;
    background-color: #d2d1d1;
}

.level-2 {
    --bs-table-bg: var(--bs-al-warm-gray-1-50);
    background-color: var(--bs-al-warm-gray-1-50);
}

.catalog-table .bg-al-row.level-2:hover {
    --bs-table-bg: #dcdbdb;
    background-color: #dcdbdb;
}

.level-3 {
    --bs-table-bg: var(--bs-al-warm-gray-1-25);
    background-color: var(--bs-al-warm-gray-1-25);
}

.catalog-table .bg-al-row.level-3:hover {
    --bs-table-bg: var(--bs-al-warm-gray-1-50);
    background-color: var(--bs-al-warm-gray-1-50);
}

.level-2 > td:first-of-type {
    text-indent: 20px;
}

.level-3 > td:first-of-type {
    text-indent: 40px;
}

.level-4 > td:first-of-type {
    text-indent: 60px;
}

.level-5 > td:first-of-type {
    text-indent: 80px;
}

.level-6 > td:first-of-type {
    text-indent: 100px;
}

i.level-1 {
    font-size: 1rem;
    --bs-table-bg: inherit;
    background-color: inherit;
}

i.level-2 {
    padding-left: 20px;
    --bs-table-bg: inherit;
    background-color: inherit;
    font-size: 1rem;
}

i.level-3 {
    padding-left: 40px;
    --bs-table-bg: inherit;
    background-color: inherit;
    font-size: 1rem;
}

i.level-4 {
    padding-left: 60px;
    font-size: 1rem;
}

i.level-5 {
    padding-left: 80px;
    font-size: 1rem;
}

table {
    table-layout: fixed;
    font-size: 1.0rem;
}

th {
    vertical-align: middle;
    text-align: center !important;
}

th.col-1 {
    width: 5%;
}

th.col-2 {
    width: 95%;
}

th.col-3 {
    width: 30px;
}

tr.asset th {
    border-bottom-style: none !important;
}

.collapsed-tr {
    display: none;
}

.input-group-text {
    min-width: 140px;
}

tr.node {
    border-top: 1px solid var(--bs-al-warm-gray-1-25);
    border-bottom-style: none;
}

tr.asset {
    cursor: pointer;
    --bs-table-bg: var(--bs-white);
    background-color: var(--bs-white);
    border-top: 1px solid var(--bs-al-warm-gray-1);
    border-bottom-style: none;
    
}

/*    tr.node + tr.asset {
        border-top: 1px none var(--bs-al-warm-gray-1);
    }*/


tr td p.name {
    font-size: 0.95rem;
    color: var(--bs-al-black-7);
    line-height: 1rem;
}

tr td p.details {
    font-size: 0.8rem;
    color: var(--bs-al-warm-gray-9);
}

tr td p {
    margin: 12px;
}


tr td.td-asset {
    padding: 10px 0 5px 0;
    margin: 0;
    line-height: 0.5rem;
    display: flex;
    border-bottom-style: none;
}

tr td.td-model {
    padding: 0;
    margin: 0;
    vertical-align: middle;
}

input[type="text"]::-webkit-input-placeholder {
    color: #8C7F70 !important;
    /*color: #8C7F70 !important;*/
}

input[type="text"]::-moz-placeholder {
    color: #8C7F70 !important;
}


input[type="text"]:-ms-input-placeholder {
    color: #8C7F70 !important;
}

.td-asset-search {
    text-indent: 0 !important;
}

.table > :not(:first-child) {
    border-style: none !important;
}


.svg-icon {
    margin-top: 9px;
}

.asset-icon {
    font-size: 40px;
    max-width: 50px;
    min-width: 50px;
    color: var(--bs-al-blue);
    
}

.asset-info {
    text-indent: 0px;
    flex-grow: 2;
}

tr.node .icon-download {
    display: none;
}
tr.asset .icon-download, .language-dropdown {
    visibility: hidden;
}

tr.asset .download {
 
    border-bottom-style: none;
}

tr.asset:hover .download {
    border-top: 1px double var(--bs-al-warm-gray-1);
    margin: 0;
    padding: 0;
    --bs-table-bg: var(--bs-al-blue);
    background-color: var(--bs-al-blue);
    text-align: center;
    vertical-align: bottom;
    color: var(--bs-white);
}
tr.asset:hover .icon-download {
    visibility: visible; 
}

tr.asset:hover .language-dropdown {
    margin-top: 3px;
    margin-right: 3px;
    visibility: visible;
    border: 1px solid var(--bs-al-warm-gray-1);
    border-radius: 3px;
}

.language-dropdown:focus {
    box-shadow: none !important;
}

.legacy-icon {
    margin-right: 7px;
}

.tableDiv {
    border: 1px solid black;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
}

.sizes {
    background-color: var(--bs-al-blue) !important;
    color: var(--bs-white) !important;
    position: relative;
    padding: 0.4rem;
    font-size: 0.8rem;
    display: inline-block;
    margin: 0px 2px !important;
    border: 1px solid var(--bs-al-warm-gray-1-50) !important;
}

.cost {
    background-color: #B5B1B1 !important;
    color: black !important;
    position: relative;
    padding: 0.4rem;
    font-size: 0.8rem;
    display: inline-block;
    margin: 0.4rem 2px !important;
    border: 1px solid var(--bs-al-warm-gray-1-50) !important;
}

.group {
    display: inline;
    position: relative;
    margin: 0px 2px !important;
    padding-left: 5px;
    float: left;
}

.supplier {
    color: black !important;
    background-color: #B5B1B1 !important;
    position: relative;
    padding: 0.4rem;
    font-size: 0.8rem;
    display: inline-block;
    margin: 0 2px 0.4rem 2px !important;
}

.extrasIndent {
    margin-left: 20px;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 0.9rem;
}

.unitSize {
    margin-right: 5px;
    line-height: 1.0;
}

h6 {
    padding: 5px 5px 0px 5px;
}

.extrasLinks {
    margin-left: 20px;
    margin-bottom: 10px;
    display: block;
    font-size: 0.85rem;
}

.extrasButton {
    background: none;
    border: none;
    color: #0D6EFD;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.85rem;
    padding: 0;
    margin-left: 20px;
    margin-bottom: 10px;
}

.extrasButton:hover {
    color: #11387F;
}