.menu {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr 1fr;
}

.item {
}

.toprow {
    grid-column-start: 1;
    grid-column-end: 5;
    height: 6px;
    background-color: var(--bs-al-warm-gray-1-25);
}

.pathrow {
    height: 26px;
    background-color: var(--bs-al-warm-gray-1-25);
}

.padding-left {
    grid-row-start: 2;
    grid-row-end: 4;
    background-color: var(--bs-white);
}

.padding-right {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 4;
    background-color: var(--bs-al-warm-gray-1-25);
}



.bootomrow {
    grid-column-start: 2;
    grid-column-end: 4;
    height: 62px;
    align-items: baseline;
    display: flex;
}

    .bootomrow > img {
        max-height: 44px;
        margin-right: 28px;
    }

    .bootomrow > .heading {
        color: var(--bs-al-blue);
        font-size: 1.4rem;
        font-weight: 300;
    }

    .bootomrow .link {
        flex: auto;
        text-align: right;
    }

        .bootomrow .link > a {
            color: var(--bs-al-blue);
            text-decoration: none;
            background-image: linear-gradient(var(--bs-al-blue),var(--bs-al-blue));
            background-size: 0 1px,auto;
            background-repeat: no-repeat;
            background-position: bottom;
            transition-duration: 175ms;
            padding: 8px 16px;
            position: relative;
        }
            .bootomrow .link > a:hover {
                cursor: pointer;
                background-size: 37px 1px;
                text-decoration: none;
                color: var(--bs-al-blue);
            }

            .path-white {
                border-top-right-radius: 12px;
                background-color: var(--bs-white);
                height: 100%;
            }

.path-grey {
    background-color: var(--bs-white);
}

    .path-grey > .path {
        border-bottom-left-radius: 12px;
        background-color: var(--bs-al-warm-gray-1-25);
        height: 100%;
        padding-left: 20px;
        white-space: nowrap;
    }

        .path-grey > .path > span {
            margin-right: 20px;
            font-size: 0.8rem;
        }

.divider {
    grid-column-start: 1;
    grid-column-end: 5;
    height: 5px;
    background-color: var(--bs-al-warm-gray-1-25);
    margin-bottom: 20px;
}
.roleDropDown {
    font-size: 0.8rem !important;
    max-height: 26px !important;
    padding: 0 !important;
    border-style: none !important;
    vertical-align: inherit !important;
}

.roleDropDownItems {
    transform: translate(0px, 17px) !important;
}


.menutext {
    color: #1E1C77;
    display: inline-block;
    padding-bottom: 3px;
    margin: 5px 0 0 30px;
    height: 20px;
}

.menulink {
    color: #1E1C77;
    display: inline-block;
    padding-bottom: 3px;
    margin: 5px 0 0 30px;
    height: 20px;
    background-image: linear-gradient(#1e1c77,#1e1c77);
    background-size: 0 1px,auto;
    background-repeat: no-repeat;
    background-position: bottom;
    transition-duration: 175ms;
}