.rbt-input-multi {
    height: 38px;
}

.rbt-input-multi.focus {
    color: #433D3D;
    background-color: #fff;
    border-color: #c5beb7;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(140,127,112,0.25);
    box-shadow: 0 0 0 0.2rem rgba(140,127,112,0.25);
}

.al-token {
    background-color: var(--bs-al-blue);
    color: var(--bs-white);
}

