.documentTypeBtn {
    background-color: var(--bs-al-warm-gray-1-25) !important;
    color: var(--bs-al-black-7) !important;
    border: 1px solid var(--bs-al-warm-gray-1-50) !important;
    margin: 0px 2px !important;
    font-size: 1.0rem !important;
}

    .documentTypeBtn:active, .documentTypeBtn.active {
        background-color: var(--bs-al-blue) !important;
        color: var(--bs-white) !important;
    }

.documentTypeBtn:focus {
    box-shadow: none !important;
}